import { chunk } from "lodash-es";
import type { DeepPartial } from "~/utils/deepPartial";
import type { NotificationData } from "~/utils/notification";
import { generateNotificationText } from "~/utils/notification";
import { notificationColumns } from "~/utils/supabase.columns";
import type { Notification } from "~/utils/supabase.types";

// We omit the json data field cause it will be pick from the NotificationData type (and Json type is too complex for vue in some components)
export type NotificationCasted = Omit<Notification, "data"> & DeepPartial<NotificationData>;

const castNotification = (notification: Notification): NotificationCasted => {
  return notification as NotificationCasted;
};

const notifications = ref<NotificationCasted[]>([]);
const countUnread = ref(0);

export const useNotification = function () {
  const { authUser, userRoles } = useUserMe();
  const { addToastError } = useToast();
  const supabase = useSupabase();
  // const config = useRuntimeConfig();

  const loading = ref(true);

  const queryUserNotifications = async (): Promise<NotificationCasted[]> => {
    if (!authUser.value) {
      addToastError({
        description: "Impossible de récupérer ton compte, tente de te reconnecter.",
        title: "Erreur avec la connexion à Slack",
      });
      throw new Error("Impossible de récupérer ton compte, tente de te reconnecter.");
    }

    loading.value = true;

    const { data, error } = await supabase
      .from("notification")
      .select(notificationColumns)
      .or(`recipient_user.eq.${authUser.value.id},recipient_roles.ov.{${userRoles.value}}`)
      // .eq("is_read", false)
      .order("created_at", { ascending: false });

    loading.value = false;

    if (error) {
      addToastError({ description: "Impossible de récupérer les notifications." }, error);
      throw error;
    }

    return data.map(castNotification);
  };

  onMounted(async () => {
    notifications.value = await queryUserNotifications();
    countUnread.value = await countUserNotifications();
  });

  const countUserNotifications = async () => {
    if (!authUser.value) return 0;
    const { count, error } = await supabase
      .from("notification")
      .select(notificationColumns, { count: "exact", head: true })
      .or(`recipient_user.eq.${authUser.value.id},recipient_roles.ov.{${userRoles.value}}`)
      .eq("is_read", false);

    if (error) {
      addToastError({ description: "Impossible de récupérer le nombre de notifications." }, error);
      throw new Error(error.message);
    }

    return count ?? 0;
  };

  const markAsRead = async (notificationToUpdate: NotificationCasted) => {
    notifications.value = updateCollection(notifications.value, { ...notificationToUpdate, is_read: true });

    const { error } = await supabase
      .from("notification")
      .update({ is_read: true })
      .eq("id", notificationToUpdate.id)
      .select()
      .single();

    if (error) {
      addToastError({ description: "Impossible de trouver la page correspondante." });
      throw error;
    }

    countUnread.value = Math.max(0, countUnread.value - 1);
  };

  const navigateToLink = (notification: NotificationCasted) => {
    const url = notification.data?.navigateTo;

    if (!url) {
      addToastError({ description: "Impossible de trouver la page correspondante." });
      throw new Error("Impossible de trouver la page correspondante.");
    }

    const isExternal = url.startsWith("http");
    navigateTo(url, isExternal ? { external: true } : {});
  };

  const markAllAsRead = async () => {
    notifications.value = notifications.value.map((notification) => ({ ...notification, is_read: true }));
    // const notificationIds = notifications.value.map((notification) => notification.id);

    const chunkSize = 200; // 200 seems to be the limit.
    const chunks = chunk(notifications.value, chunkSize);

    await Promise.all(
      chunks.map(async (notifications) => {
        const notificationIds = notifications.map((notification) => notification.id);
        const { error } = await supabase.from("notification").update({ is_read: true }).in("id", notificationIds);
        if (error) {
          addToastError({ description: "Impossible de trouver la page correspondante." });
          throw error;
        }
      })
    );

    countUnread.value = 0;
  };

  const unreadNotifications = computed(() => notifications.value.filter((notification) => !notification.is_read));

  const isNotificationImportant = (notification: NotificationCasted) => {
    return userRoles.value.some((role) => {
      const notificationsForRole = importantNotifications[role];
      if (!notificationsForRole) return false;

      return notificationsForRole.includes(notification.type);
    });
  };

  const isNotificationBadged = (notification: NotificationCasted) => {
    return notification.data?.workspace === "external" || isNotificationImportant(notification);
  };

  return {
    notifications,
    unreadNotifications,
    countUnread,
    loading,
    markAsRead,
    markAllAsRead,
    navigateToLink,
    countUserNotifications,
    refresh: queryUserNotifications,
    generateNotificationText,
    isNotificationImportant,
    isNotificationBadged,
  };
};
